import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

interface Props {
    seconds: number;
    callbackHandler: () => void;
}

const CountDown: FunctionComponent<Props> = ({ seconds, callbackHandler }) => {
    const [timer, setTimer] = useState(seconds);
    const id = useRef(0);
    const clear = () => {
        window.clearInterval(id.current);
    };
    useEffect(() => {
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1);
        }, 1000);
        return () => clear();
    }, []);

    useEffect(() => {
        if (timer === 0) {
            clear();
            callbackHandler();
        }
    }, [timer]);

    return <span className="Timer">{timer}</span>;
};

export default CountDown;
