import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';
// import { useConfig } from '../context/ConfigContext';
import AxiosInstance from '../utils/axios-instance';

export type AxiosFetchResponse<T> = {
    request: Promise<AxiosResponse<T, any>> | undefined;
    response: AxiosResponse<T, any> | undefined;
    data: T | undefined;
    loading: boolean;
    // error: boolean;
    // errorMessage: null;
    sendRequest: () => Promise<AxiosResponse<T, any>>;
    cancelRequest: () => void;
};

export interface AxiosFetchProps {
    url: string | null;
    method?: string;
    body?: any;
}

const useAxiosFetch = <T>(props: AxiosFetchProps): AxiosFetchResponse<T> => {
    // const { loading: loadingConfig } = useConfig();
    const [request, setRequest] = useState<Promise<AxiosResponse<T, any>>>();
    const [response, setResponse] = useState<AxiosResponse<T, any>>();
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(true);

    const unmounted = useRef<boolean>(false);
    const cancelTokenSource = useRef<CancelTokenSource>();
    const isCancelled = useRef<boolean>(false);

    const cancelRequest = () => {
        // console.log('cancelRequest');
        if (!data && !isCancelled.current) {
            unmounted.current = true;
            isCancelled.current = true;
            cancelTokenSource.current?.cancel('Request cancelled');

            // setResponse(undefined);
            setData(undefined);
            setLoading(false);
            // console.log('actual cancelRequest');
        }
    };

    const sendRequest = () => {
        setLoading(true);
        setData(undefined);
        unmounted.current = false;
        isCancelled.current = false;
        cancelTokenSource.current = Axios.CancelToken.source();

        const url = props.url;

        if (url !== null) {
            // console.log('url', url);
            const getData = () => {
                let bodyData: any = {
                    cancelToken: cancelTokenSource.current?.token,
                    // cancelToken: source?.token,
                    // timeout: timeout,
                };
                if (props.body) {
                    bodyData = { ...bodyData, ...props.body };
                }

                // console.log('Axios.defaults.headers.common', AxiosInstance.defaults.headers.common);
                let request: Promise<AxiosResponse<T, any>>;
                let data: T;
                if (props.method === 'post') {
                    request = AxiosInstance.post<T>(url, bodyData);
                } else {
                    request = AxiosInstance.get<T>(url, bodyData);
                }

                setRequest(request);

                request
                    .then((r) => {
                        if (!unmounted.current) {
                            data = r.data;
                            setData(data);
                            setResponse(r);
                            setLoading(false);
                        }
                    })
                    .catch(() => {
                        if (!unmounted.current) {
                            // setError(true);
                            // setErrorMessage(e.message);
                            setLoading(false);
                            // if (Axios.isCancel(e)) {
                            //     console.log(`request cancelled: ${e.message}`);
                            // } else {
                            //     console.log('another error happened:' + e.message);
                            // }
                        }
                    });
                return request;
            };

            return getData();
        } else {
            setLoading(false);

            const error = { message: 'no url' };

            return Promise.reject(error);
        }
    };

    useEffect(() => {
        // console.log('props.url', props.url);
        // if (!loadingConfig) {
        if (props.url !== null) {
            sendRequest();
        } else {
            unmounted.current = false;
            isCancelled.current = false;
            cancelTokenSource.current = Axios.CancelToken.source();

            setRequest(undefined);
            setResponse(undefined);
            setData(undefined);
            setLoading(false);
        }
        // }

        return () => {
            cancelRequest();
        };
    }, [props.url]);

    return { loading, request, response, data, sendRequest, cancelRequest };
};

export default useAxiosFetch;
