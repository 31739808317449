import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import * as Yup from 'yup';
import { BaseTenant } from '../../models/Tenant';
import Button from '../Button/Button';
import classes from './NewTenantDialog.module.scss';

interface NewTenantDialogProps {
    open: boolean;
    closeClickHandler: () => void;
    newTenantHandler: (tenant: BaseTenant) => void;
}

const validationSchema = Yup.object({
    id: Yup.string()
        .min(2, 'Minimum of 2 characters')
        .required('ID is required')
        .matches(/^[aA-zZ0-9]+$/, 'Only letters and numbers'),
    name: Yup.string()
        .min(2, 'Minimum of 2 characters')
        .required('Name is required')
        .matches(/^[aA-zZ0-9\s]+$/, 'Only letters, numbers and spaces'),
    is_public: Yup.boolean(),
});

const NewTenantDialog: FunctionComponent<NewTenantDialogProps> = (props) => {
    // const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            is_public: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // console.log(JSON.stringify(values, null, 2));
            // console.log(formik.isValid, formik.dirty, formik.errors);

            props.newTenantHandler(values);

            onCloseHandler();
        },
        enableReinitialize: true,
        validateOnBlur: true,
    });

    // const disabledSubmit = isSubmitting || !(formik.isValid && formik.dirty);

    useEffect(() => {
        formik.resetForm();
    }, []);

    const onCloseHandler = () => {
        props.closeClickHandler();
        formik.resetForm();
    };

    return (
        <Dialog
            open={props.open}
            onClose={onCloseHandler}
        >
            <form
                onSubmit={formik.handleSubmit}
                className={classes.NewTenantDialog}
            >
                <DialogTitle>Make new tenant</DialogTitle>
                <DialogContent>
                    <DialogContentText>To make a new tenant fill in all field below.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="id"
                        label="Tenant ID (Only letters and numbers allowed)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="is_public"
                                checked={formik.values.is_public}
                                // value={formik.values.is_public}
                                onChange={formik.handleChange}
                            />
                        }
                        label={`Is tenant public?`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        text="Cancel"
                        // alt
                        border
                        onClick={onCloseHandler}
                    ></Button>
                    <Button
                        text="Make tenant"
                        type="submit"
                    ></Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewTenantDialog;
