import { faHouseChimneyUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import { useConfig } from '../../../../context/ConfigContext';
import { Tenant } from '../../../../models/Tenant';
import axiosInstance from '../../../../utils/axios-instance';
import TenantItem from './TenantItem/TenantItem';
import classes from './TrashedTenants.module.scss';

interface TrashedTenantsProps {
    tenants: Tenant[];
    loading: boolean;
    onTenantRestoredHandler: (tenant: Tenant) => void;
    onTenantPermanentlyDeleteHandler: (tenant: Tenant) => void;
}

const TrashedTenants: FunctionComponent<TrashedTenantsProps> = ({
    tenants,
    loading,
    onTenantRestoredHandler,
    onTenantPermanentlyDeleteHandler,
}) => {
    const { t } = useTranslation();
    const { config } = useConfig();

    const filterDataBySearch = (items: Tenant[], searchValues: string[]) => {
        // console.log(items);
        return items.filter((item) => {
            // Specific data / array to searchable string
            const name = item.name;
            const isPublic = item.is_public ? 'public' : 'private';
            //
            const searchableValues = { name, isPublic };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    // Permanently delete trashed tenant
    const [tenantToDelete, setTenantToDelete] = useState<Tenant>();

    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const closeDeleteModal = () => setOpenDelete(false);

    const onDeleteClickHandler = (tenant: Tenant) => {
        if (tenantToDelete !== undefined) return;
        setTenantToDelete(tenant);
        setOpenDelete(true);
    };

    const onCancelDeleteClickHandler = () => {
        setTenantToDelete(undefined);
        closeDeleteModal();
    };

    const onPermanentlyDeleteHandler = () => {
        if (tenantToDelete === undefined) return;

        axiosInstance.get(`${config.baseUrl}/tenants/force/${tenantToDelete.id}`).then(() => {
            onTenantPermanentlyDeleteHandler(tenantToDelete);
            setTenantToDelete(undefined);
        });

        closeDeleteModal();
    };

    // Restore tenant
    const [tenantToRestore, setTenantToRestore] = useState<Tenant>();

    const [openRestore, setOpenRestore] = useState<boolean>(false);
    const closeRestoreModal = () => setOpenRestore(false);

    const onRestoreClickHandler = (tenant: Tenant) => {
        if (tenantToRestore !== undefined) return;
        setTenantToRestore(tenant);
        setOpenRestore(true);
    };

    const onCancelRestoreClickHandler = () => {
        setTenantToRestore(undefined);
        closeRestoreModal();
    };

    const onRestoreHandler = () => {
        if (tenantToRestore === undefined) return;

        axiosInstance.get(`${config.baseUrl}/tenants/restore/${tenantToRestore?.id}`).then(() => {
            onTenantRestoredHandler(tenantToRestore);
            setTenantToRestore(undefined);
        });

        closeRestoreModal();
    };

    const renderListHeaders = (
        <div className={classes.TenantsListHeader}>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListId}`}>{t('Common:ID_LABEL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListName}`}>{t('Common:NAME_LABEL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListAvailability}`}>{t('Common:ACTIONS_LABEL')}</h3>
            <div className={`${classes.TenantsListHeaderItem} ${classes.TenantControls}`}></div>
        </div>
    );

    const renderTenantItem = (tenant: Tenant) => {
        return (
            <TenantItem
                tenant={tenant}
                onRestoreClickHandler={onRestoreClickHandler}
                onDeleteClickHandler={onDeleteClickHandler}
            />
        );
    };

    return (
        <div className={classes.TrashedTenants}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{t('Common:TRASHED_TENANTS_HEADER')}</h2>
                <div className={classes.TenantsStat}>
                    <FontAwesomeIcon icon={faHouseChimneyUser}></FontAwesomeIcon>

                    <span className={classes.TenantsAmount}>{tenants.length}</span>
                </div>
            </div>

            <div className={`Box ${classes.ManagementTrashedTenantsListBox}`}>
                <ExtendedMuiList
                    loading={loading}
                    filterDataBySearch={filterDataBySearch}
                    searchInputLabel={t('Common:TENANTS_SEARCH_INPUT_LABEL')}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_TENANTS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_TENANTS')}
                    items={tenants}
                    renderListHeaders={renderListHeaders}
                    renderItem={renderTenantItem}
                    itemsListContainerClasses={`${classes.TenantListContainer}`}
                    itemsListClasses={`${classes.TenantList}`}
                />
            </div>

            <Dialog
                open={openRestore}
                onClose={closeRestoreModal}
            >
                <DialogTitle>{t('Common:RESTORE_TENANT_DIALOG_TITLE')}</DialogTitle>
                <DialogContent>{t('Common:RESTORE_TENANT_DIALOG_MESSAGE')}</DialogContent>
                <DialogActions>
                    <Button
                        text={t('Common:BUTTON_CANCEL')}
                        onClick={onCancelRestoreClickHandler}
                    />
                    <Button
                        text={t('Common:BUTTON_CONFIRM_RESTORE')}
                        onClick={onRestoreHandler}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDelete}
                onClose={closeDeleteModal}
            >
                <DialogTitle>{t('Common:DELETE_TENANT_DIALOG_TITLE')}</DialogTitle>
                <DialogContent>{t('Common:DELETE_TENANT_DIALOG_MESSAGE')}</DialogContent>
                <DialogActions>
                    <Button
                        text={t('Common:BUTTON_CANCEL')}
                        onClick={onCancelDeleteClickHandler}
                    />
                    <Button
                        text={t('Common:BUTTON_CONFIRM_DELETE')}
                        onClick={onPermanentlyDeleteHandler}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TrashedTenants;
