import { faTrashAlt, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormControlLabel, Input, Switch } from '@mui/material';
import debounce from 'lodash-es/debounce';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { useConfig } from '../../../../context/ConfigContext';
import { Tenant } from '../../../../models/Tenant';
import Axios from '../../../../utils/axios-instance';
import classes from './TenantItem.module.scss';

interface TenantItemProps {
    tenant: Tenant;
    onDeleteTenantHandler: (tenant: Tenant) => void;
}

const TenantItem: FunctionComponent<TenantItemProps> = ({
    tenant,
    // roles,
    // onDeactivateTenantHandler,
    // onActivateTenantHandler,
    onDeleteTenantHandler,
}) => {
    const { config } = useConfig();
    const [name, setName] = useState(tenant.name);
    const [isPublic, setIsPublic] = useState(tenant.is_public);

    const updateTenant = (updateData: Partial<Tenant>) => {
        return Axios.put(`${config.baseUrl}/tenants/${tenant.id}`, updateData);
    };

    const debouncedUpdateHandler = useMemo(
        () =>
            debounce((value) => {
                // console.log(value);
                updateTenant(value);
            }, 500),

        []
    );

    const handleNameChange = (event) => {
        setName(event.target.value);

        debouncedUpdateHandler({ name: event.target.value, is_public: isPublic });
    };

    const handleIsPublicChange = (event) => {
        setIsPublic(event.target.checked);

        updateTenant({ name: name, is_public: event.target.checked });
    };

    const onVisitHandler = () => {
        window.open(
            `${process.env.REACT_APP_URL_SCHEME}${tenant.id}${process.env.REACT_APP_URL_SLD_SUFFIX || ''}.${
                process.env.REACT_APP_URL_TLD
            }/app`
        );
    };

    return (
        <div className={classes.TenantItem}>
            <div className={`${classes.TenantsListItem} ${classes.TenantListId} wrap-text`}>{tenant.id}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListName} wrap-text`}>
                <FormControl variant="standard">
                    <Input
                        name="name"
                        id="component-simple"
                        value={name}
                        onChange={handleNameChange}
                        // onBlur={handleNameChange}
                    />
                </FormControl>
            </div>

            <div className={`${classes.TenantsListItem} ${classes.TenantListAvailability}`}>
                <FormControlLabel
                    control={
                        <Switch
                            name="is_public"
                            value={isPublic ?? ''}
                            checked={isPublic ?? false}
                            onChange={handleIsPublicChange}
                            // onBlur={handleIsPublicChange}
                        />
                    }
                    label={`${isPublic ? 'PUBLIC' : 'PRIVATE'}`}
                />
            </div>

            <div className={classes.TenantControls}>
                <Button
                    onClick={() => {
                        onVisitHandler();
                    }}
                    icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                />
                <Button
                    text=""
                    danger
                    onClick={() => {
                        onDeleteTenantHandler(tenant);
                    }}
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                />
            </div>
        </div>
    );
};

export default TenantItem;
