import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { StyleData } from '../models/Theme';

// import useAxiosFetch from '../hooks/useAxiosFetch';
// import { useConfig } from './ConfigContext';
import { CtxProvider } from './ThemeContext';
import { getColor } from '../utils/colors';

type ThemeProviderProps = {
    children?: ReactNode;
    overrides?: Partial<StyleData>;
};

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, overrides }) => {
    const defaultTheme: StyleData = {
        id: 0,
        name: '',
        css: '',
        active: false,
    };
    const [theme, setTheme] = useState<StyleData>({
        ...defaultTheme,
        ...overrides,
    });

    // const { config } = useConfig();
    // const { response } = useAxiosFetch(`${config.baseUrl}/style/active`);

    const MuiTheme = createTheme({
        typography: {
            fontFamily: getColor('--header-font-family'),
        },
        palette: {
            error: {
                main: getColor('--brand-danger-color'),
            },
            warning: {
                main: getColor('--brand-alert-color'),
            },
            info: {
                main: getColor('--brand-color-5'),
            },
            success: {
                main: getColor('--brand-color-4'),
            },
            primary: {
                main: `rgb(${getColor('--brand-color-5-rgb')})`,
            },
            text: {
                primary: getColor('--text-color'),
            },
        },

        // TODO how to override in MUI v5

        // overrides: {
        //     MuiInput: {
        //         input: {
        //             height: 40,
        //         },
        //     },
        // },
    });

    // useEffect(() => {
    //     if (response) {
    //         // console.log('Data Loaded', response.data);
    //         const activeStyle: StyleData = response.data;
    //         setTheme((prevState) => {
    //             if (prevState.css !== activeStyle.css) {
    //                 // console.log(prevState.css, activeStyle.css);
    //                 return activeStyle;
    //             }

    //             return { ...prevState, ...activeStyle };
    //         });
    //     }
    //     return () => {
    //         // TODO: Reset to default or null;
    //     };
    // }, [response]);

    return (
        <CtxProvider value={{ theme, setTheme }}>
            <MuiThemeProvider theme={MuiTheme}>{children}</MuiThemeProvider>
        </CtxProvider>
    );
};
