import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useConfig } from '../../../context/ConfigContext';
import useAxiosFetch from '../../../hooks/useAxiosFetch';
import { Tenant } from '../../../models/Tenant';
import axiosInstance from '../../../utils/axios-instance';
import QuestsList from './QuestsList/QuestsList';
import classes from './TenantEditDetail.module.scss';
import TenantItem from './TenantItem/TenantItem';

type ParamTypes = {
    tenantId: string;
};

const TenantEditDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { tenantId } = useParams<ParamTypes>();
    const { config } = useConfig();
    const navigate = useNavigate();

    const { data: dataTenant } = useAxiosFetch<Tenant>({
        url: `${config.baseUrl}/tenants/${tenantId}`,
        method: 'get',
    });

    const [tenant, setTenant] = useState<Tenant>();
    const [tenantToDelete, setTenantToDelete] = useState<Tenant>();

    useEffect(() => {
        if (dataTenant) {
            // console.log(dataTenant);
            setTenant(dataTenant);
        }
    }, [dataTenant]);

    const [open, setOpen] = useState<boolean>(false);
    const closeModal = () => setOpen(false);

    const onCancelDeleteHandler = () => {
        setTenantToDelete(undefined);
        closeModal();
    };

    const onDeleteTenantHandler = (tenant: Tenant) => {
        if (tenantToDelete !== undefined) return;
        setTenantToDelete(tenant);
        setOpen(true);
    };

    const onPermanentlyDeleteHandler = () => {
        if (tenantToDelete === undefined) return;

        axiosInstance.delete(`${config.baseUrl}/tenants/${tenantToDelete.id}`).then(() => {
            navigate('/', { replace: true });
        });

        setTenantToDelete(undefined);
        setOpen(false);
    };

    return (
        <div className={classes.TenantEditDetail}>
            <PageHeader
                title={t('Common:TENANT_EDIT_HEADER')}
                underline={false}
            />
            <div className={`Box ${classes.EditTenantsBox}`}>
                <h3 className={classes.QuestListHeader}>{t('Common:TENANT_DETAILS_HEADER')}</h3>
                {tenant !== undefined && (
                    <>
                        <TenantItem
                            tenant={tenant}
                            onDeleteTenantHandler={onDeleteTenantHandler}
                        />
                        <QuestsList owner={tenant} />
                    </>
                )}
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('Common:DELETE_TENANT_WARNING_TITLE')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Common:DELETE_TENANT_WARNING_MESSAGE', { name: tenantToDelete?.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        text={t('Common:BUTTON_CANCEL')}
                        onClick={onCancelDeleteHandler}
                    />
                    <Button
                        text={t('Common:BUTTON_PERMANENTLY_DELETE')}
                        onClick={onPermanentlyDeleteHandler}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TenantEditDetail;
