import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Permission, User } from '../models/User';
import Axios from '../utils/axios-instance';
import { getLocalStorageUser } from '../utils/flavourstorage';
import { Auth, CtxProvider } from './AuthContext';

type Props = {
    children?: ReactNode;
};

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
    const defaultAuth: Auth = { isLoggedIn: false, currentUser: undefined };
    const [auth, setAuth] = useState<Auth>(defaultAuth);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (location) {
            checkSession();
        }
    }, [location]);

    // useEffect(() => {
    //     checkSession();
    // }, []);

    const checkSession = () => {
        const user = loggedInUser();
        if (!user) {
            setAuth({ isLoggedIn: false, currentUser: undefined });
        } else {
            // console.log('found localStorage user -> Auto-login:', user);
            // Auto-login
            autoLogin(user);
        }
    };

    const loggedInUser = () => {
        const localUser = getLocalStorageUser();

        if (!localUser) {
            return null;
        } else {
            const user: User = JSON.parse(localUser);
            return user;
        }
    };

    const autoLogin = (user: User) => {
        // Set headers with user access token
        Axios.defaults.headers.common = { Authorization: `Bearer ${user.token.access_token}` };
        // console.log('Axios.defaults.headers.common', Axios.defaults.headers.common);
        // Set Auth isLoggedIn with user
        setAuth({ isLoggedIn: true, currentUser: user });
    };

    const logout = () => {
        setAuth({ isLoggedIn: false, currentUser: undefined });
        toast.success(t('Common:TOAST_LOGOUT_SUCCESS'));
    };

    const hasPermission = (permission: Permission) => {
        if (!auth.currentUser) {
            return false;
        } else {
            if (!auth.currentUser?.permissions) {
                return false;
            }

            // console.log('checking permission:', permission, auth.currentUser?.permissions);
            const checkPermission = auth.currentUser?.permissions.find((p) => {
                return p === permission;
            });
            return Boolean(checkPermission);
        }
    };

    return <CtxProvider value={{ auth, setAuth, checkSession, autoLogin, logout, hasPermission }}>{children}</CtxProvider>;
};
