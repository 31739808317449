import { faHouseChimneyUser, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import NewTenantDialog from '../../../../components/NewTenantDialog/NewTenantDialog';
import { useConfig } from '../../../../context/ConfigContext';
import { BaseTenant, Tenant } from '../../../../models/Tenant';
import axiosInstance from '../../../../utils/axios-instance';
import TenantItem from './TenantItem/TenantItem';
import classes from './Tenants.module.scss';

interface TenantsProps {
    tenants: Tenant[];
    loading: boolean;
    onTenantsUpdateHandler: (tenants: Tenant[]) => void;
}

const Tenants: FunctionComponent<TenantsProps> = ({ tenants, loading, onTenantsUpdateHandler }) => {
    const { t } = useTranslation();
    const { config } = useConfig();

    const [openNewTenant, setOpenNewTenant] = React.useState(false);
    const handleOpenNewTenant = () => {
        setOpenNewTenant(true);
    };

    const handleCloseNewTenant = () => {
        setOpenNewTenant(false);
    };

    const filterDataBySearch = (items: Tenant[], searchValues: string[]) => {
        // console.log(items);
        return items.filter((item) => {
            // Specific data / array to searchable string
            const name = item.name;
            const isPublic = item.is_public ? 'public' : 'private';
            //
            const searchableValues = { name, isPublic };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const renderTenantItem = (tenant: Tenant) => {
        return <TenantItem tenant={tenant} />;
    };

    const onAddTenantsHandler = () => {
        handleOpenNewTenant();
    };

    const handleNewTenant = (tenant: BaseTenant) => {
        axiosInstance
            .post(`${config.baseUrl}/tenants`, tenant)
            .then((response) => {
                // console.log(response);
                onTenantsUpdateHandler([...tenants, response.data]);
            })
            .catch((error) => console.log(error));
    };

    const [userCount, setUserCount] = useState<number>(0);
    useEffect(() => {
        let count = 0;
        tenants.map((tenant) => {
            count += tenant.user_count;
        });
        setUserCount(count);
    }, [tenants]);

    const renderListHeaders = (
        <div className={classes.TenantsListHeader}>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListId}`}>{t('Common:ID_LABEL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListName}`}>{t('Common:NAME_LABEL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListUserCount}`}>{t('Common:USER_COUNT')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListAvailability}`}>{t('Common:AVAILABILITY_LABEL')}</h3>
            <div className={`${classes.TenantsListHeaderItem} ${classes.TenantControls}`}></div>
        </div>
    );

    return (
        <div className={classes.Tenants}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{t('Common:TENANTS_HEADER')}</h2>
                <div className={classes.TenantsStat}>
                    <FontAwesomeIcon icon={faHouseChimneyUser}></FontAwesomeIcon>

                    <span className={classes.TenantsAmount}>{tenants.length}</span>
                </div>
                <div className={classes.TenantsUsersStats}>
                    <FontAwesomeIcon icon={faUser} />
                    <span className={classes.TenantsUserCount}>{userCount}</span>
                </div>
                <div className="flex-grow"></div>
                <Button
                    text={t('Common:BUTTON_ADD_TENANT')}
                    alt
                    border
                    onClick={onAddTenantsHandler}
                />
            </div>

            <div className={`Box ${classes.ManagementTenantsListBox}`}>
                <ExtendedMuiList
                    loading={loading}
                    filterDataBySearch={filterDataBySearch}
                    searchInputLabel={t('Common:TENANTS_SEARCH_INPUT_LABEL')}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_TENANTS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_TENANTS')}
                    items={tenants}
                    renderListHeaders={renderListHeaders}
                    renderItem={renderTenantItem}
                    itemsListContainerClasses={`${classes.TenantListContainer}`}
                    itemsListClasses={`${classes.TenantList}`}
                />
            </div>

            <NewTenantDialog
                open={openNewTenant}
                closeClickHandler={handleCloseNewTenant}
                newTenantHandler={handleNewTenant}
            ></NewTenantDialog>
        </div>
    );
};

export default Tenants;
