import { format as formatDate, isDate } from 'date-fns';
import { enGB, enUS, nl, nlBE } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendHTTP from 'i18next-http-backend';
import { default as BackendLocize, default as I18NextLocizeBackend } from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

type LocalesType = {
    [key: string]: Locale;
};

export const locales: LocalesType = {
    enGB,
    enUS,
    nl,
    nlBE,
};

const isProduction = process.env.REACT_APP_ENV === 'production';
const isStaging = process.env.REACT_APP_ENV === 'staging';

const locizeOptions: I18NextLocizeBackend.BackendOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY || '',
    referenceLng: 'en',
    version: process.env.REACT_APP_LOCIZE_VERSION,
    allowedAddOrUpdateHosts: ['admin.herocenter.local'],
};

const productionBackendOptions: I18NextLocizeBackend.BackendOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
    loadPath: '/locales/{{lng}}/{{ns}}.json',
};

if (!isProduction) {
    // locize-lastused
    // sets a timestamp of last access on every translation segment on locize
    // -> safely remove the ones not being touched for weeks/months
    // https://github.com/locize/locize-lastused
    // i18n.use(LastUsed);
    i18n.use(BackendLocize);
} else {
    i18n.use(BackendHTTP);
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
        {
            // lng: 'nl', // Language to use (overrides language detection)
            fallbackLng: {
                default: ['en'],
            },
            ns: ['Auth', 'Common', 'Date', 'Login'],
            load: 'languageOnly',
            defaultNS: 'Common',
            debug: false,
            saveMissing: !isProduction, // do not use saveMissing in production
            saveMissingTo: 'current',
            interpolation: {
                escapeValue: false,
                format: (value, format, lng) => {
                    if (isDate(value)) {
                        if (lng && format) {
                            const locale = locales[lng];
                            return formatDate(value, format, { locale });
                        }
                    }

                    return value;
                },
            },
            backend: !isProduction && !isStaging ? locizeOptions : productionBackendOptions,
            locizeLastUsed: locizeOptions,
        },
        () => {
            // if (err) return console.log('something went wrong loading', err);
            // console.log(
            //     `%ci18n language: %c${i18n.language}`,
            //     'color: #264567; font-size: 12px',
            //     `color: #264567; font-size: 12px; font-weight: bold;`
            // );
            // console.groupEnd();
        }
    );

export default i18n;
