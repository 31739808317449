import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Permission } from '../../models/User';
import { getLocalStorageUser } from '../../utils/flavourstorage';

type PrivateRouteProps = {
    permission?: Permission;
    children: JSX.Element;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const { hasPermission } = useAuth();
    const location = useLocation();
    const getAuthenticatedUser = () => {
        const loggedInUser = getLocalStorageUser();
        // console.log('loggedInUser:', loggedInUser);
        return loggedInUser;
    };

    // TODO check specific for manage and desktop
    const permission = props.permission ? hasPermission(props.permission) : true;
    const condition = getAuthenticatedUser() && permission;

    return condition ? (
        props.children
    ) : // <Route
    //     path={props.path}
    //     element={props.component}
    // />
    permission ? (
        <Navigate
            to="/login"
            state={{ from: location }}
            replace
        />
    ) : (
        // <Redirect to={'/login'} />
        // <Redirect to={'/'} />
        <Navigate to="/oeps" />
    );
};
export default PrivateRoute;
