import { faBars, faHouseChimneyUser, faHouseUser, faRightFromBracket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import Popup from 'reactjs-popup';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigContext';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import AxiosInstance from '../../utils/axios-instance';
import Button from '../Button/Button';
import DropDown, { DropDownSection, ItemLocation, ItemType } from '../DropDown/DropDown';
import Logo from '../Logo/Logo';
import NavButton from '../NavButton/NavButton';
import NavDropDown from '../NavDropDown/NavDropDown';
import classes from './Navigation.module.scss';

const Navigation: FunctionComponent = () => {
    const { config } = useConfig();
    // const history = useHistory();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, logout, hasPermission } = useAuth();
    const { t } = useTranslation();
    const isDesktopMediaQuery = useMediaQuery('(min-width: 576px)');
    // const [logo, setLogo] = useState('');
    // const [defaultLogo, setDefaultLogo] = useState('');

    // useEffect(() => {
    //     if (!loadingConfig) {
    //         const customFile = config.tenant.images['logo.png']?.find((file) => file.is_default == false);
    //         const defaultFile = config.tenant.images['logo.png']?.find((file) => file.is_default == true);
    //         setDefaultLogo(
    //             getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
    //         );
    //     }
    // }, [loadingConfig]);

    const onSignOutClick = async () => {
        try {
            await AxiosInstance.post(`${config.baseUrl}/logout`, {});
        } catch (error) {
            // console.log(error);
        }

        // No need to wait for API logout, clean up storage, set authcontext and goto login
        localStorage.clear();
        logout();
        navigate('/login', { replace: true });
        // history.replace('/login');
    };

    const sections: DropDownSection[] = [
        {
            id: 0,
            value: t('Common:NAV_LINK_MANAGEMENT'),
            type: ItemType.Section,
            permission: 'user.view',
            items: [
                {
                    id: 2,
                    value: t('Common:NAV_LINK_MANAGEMENT_TENANTS'),
                    type: ItemType.Link,
                    link: 'home',
                    // permission: 'tenants.manage',
                    icon: faHouseChimneyUser,
                },
            ],
        },
        // {
        //     id: 2,
        //     value: '',
        //     type: ItemType.Section,
        //     permission: 'permission.manage',
        //     items: [
        //         {
        //             id: 0,
        //             value: t('Common:NAV_LINK_INVITE_MEMBERS'),
        //             type: ItemType.Link,
        //             link: 'invites',
        //             // permission: 'user.manage',
        //             icon: faUserPlus,
        //         },
        //     ],
        // },
        {
            id: 1,
            value: '',
            type: ItemType.Section,
            permission: 'user.view',
            items: [
                {
                    id: 0,
                    value: t('Common:SIGN_OUT'),
                    type: ItemType.Button,
                    location: ItemLocation.End,
                    onClick: onSignOutClick,
                    icon: faRightFromBracket,
                },
            ],
        },
    ];

    const [dropDownItems, setDropDownItems] = useState<DropDownSection[]>([]);

    useEffect(() => {
        if (auth && auth.isLoggedIn) {
            const sectionsPermitted = sections.map((section) => {
                const items = section.items.filter((item) => {
                    // NO permission needed
                    if (!item.permission) {
                        return true;
                    }
                    // Permission needed
                    return hasPermission(item.permission) && isDesktop;
                });
                return { ...section, items: items };
            });

            setDropDownItems(sectionsPermitted.filter((section) => section.items.length > 0));
        }
    }, [auth]);

    const dropDownOnClose = () => {
        // console.log('closing dropdown');
    };

    const onHomeClickHandler = () => {
        // history.replace('/home');
        navigate('/', { replace: true });
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    useDisableBodyScroll(open);

    const desktopNavigation = (
        <div className={classes.NavigationGroup}>
            {/* <NavButton
                to="/quests"
                label={t('Common:NAV_LINK_QUESTS_LABEL')}
            >
                <FontAwesomeIcon
                    className={classes.QuestsIcon}
                    icon={faMapMarkedAlt}
                />
            </NavButton>

            <NavButton
                to="/library"
                label={t('Common:NAV_LINK_LIBRARY_LABEL')}
            >
                <FontAwesomeIcon
                    className={classes.LibraryIcon}
                    icon={faLandmark}
                />
            </NavButton>

            <NavButton
                to="/groups"
                label={t('Common:NAV_LINK_GROUPS_LABEL')}
            >
                <FontAwesomeIcon
                    className={classes.GroupsIcon}
                    icon={faUserFriends}
                />
            </NavButton>
            
            <NavButton
                to="/profile"
                label={t('Common:NAV_LINK_PROFILE_LABEL')}
            >
                <FontAwesomeIcon
                    className={classes.ProfileIcon}
                    icon={faAddressCard}
                />
            </NavButton> */}

            <NavDropDown
                items={dropDownItems}
                onClose={dropDownOnClose}
            ></NavDropDown>
        </div>
    );

    const mobileNavigation = (
        <div className={classes.NavigationGroup}>
            <Button
                className={classes.NavigationButton}
                text={``}
                icon={<FontAwesomeIcon icon={faBars} />}
                onClick={() => setOpen((o) => !o)}
            ></Button>
        </div>
    );

    const navigationPopup = (
        <Popup
            className={'navigation-popup'}
            open={open && !isDesktopMediaQuery}
            modal
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${classes.NavigationPopup}`}>
                <div className={`${classes.NavigationTop}`}>
                    <Button
                        className={classes.ClosePopupButton}
                        text={``}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={closeModal}
                    ></Button>
                </div>

                <div className={classes.NavigationGroupMobile}>
                    {/* <NavButton
                        to="/quests"
                        label={t('Common:NAV_LINK_QUESTS_LABEL')}
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            className={classes.QuestsIcon}
                            icon={faMapMarkedAlt}
                        />
                    </NavButton>

                    <NavButton
                        to="/library"
                        label={t('Common:NAV_LINK_LIBRARY_LABEL')}
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            className={classes.LibraryIcon}
                            icon={faLandmark}
                        />
                    </NavButton>

                    <NavButton
                        to="/groups"
                        label={t('Common:NAV_LINK_GROUPS_LABEL')}
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            className={classes.GroupsIcon}
                            icon={faUserFriends}
                        />
                    </NavButton>

                    <NavButton
                        to="/profile"
                        label={t('Common:NAV_LINK_PROFILE_LABEL')}
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            className={classes.ProfileIcon}
                            icon={faAddressCard}
                        />
                    </NavButton> */}
                </div>

                <DropDown
                    items={dropDownItems}
                    isOpen={true}
                    isDesktop={isDesktopMediaQuery}
                    onClose={closeModal}
                ></DropDown>
            </div>
        </Popup>
    );

    return (
        <nav className={classes.Navigation}>
            <ul className={classes.NavList}>
                <li>
                    {location.pathname === '/home' ||
                    location.pathname === '/login' ||
                    location.pathname.includes('/invite') ||
                    location.pathname.includes('/password') ? (
                        <button
                            className={`btn nav-button ${classes.LogoButton} ${classes.Logo}`}
                            onClick={onHomeClickHandler}
                        >
                            <Logo />
                        </button>
                    ) : (
                        <NavButton
                            to="/home"
                            label={isDesktopMediaQuery ? t('Common:NAV_LINK_HOME_LABEL') : ''}
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon
                                className={classes.HomeIcon}
                                icon={faHouseUser}
                            />
                        </NavButton>
                    )}
                </li>
                {auth.isLoggedIn && auth.currentUser?.email_verified && (isDesktopMediaQuery ? desktopNavigation : mobileNavigation)}
            </ul>
            {navigationPopup}
        </nav>
    );
};

export default Navigation;
