import Axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
// import { history } from '../index';
import { Token, User } from '../models/User';
import { getBaseUrl } from './api';
// import API from './api';
import { getLocalStorageUser, setLocalStorageUser } from './flavourstorage';

export const api_scheme = process.env.REACT_APP_ENV === 'local' ? 'http' : 'https';
const axiosInstance = Axios.create({
    baseURL: `${api_scheme}://${process.env.REACT_APP_API_DOMAIN}/`,
});

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

export const useInterceptors = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const gotoLoginAndForceLogout = (): void => {
        navigate('/login', { replace: true, state: { data: { forecLogout: true } } });
        // history.replace('/login', { data: { forecLogout: true } });
    };

    const initInterceptors = () => {
        axiosInstance.interceptors.request.use(
            (requestConfig) => {
                // console.log(requestConfig);

                // For specific request allow only 1 at a time.
                const requestProgressCards = requestConfig.method === 'post' && requestConfig.url?.includes('/progress/cards/');
                if (requestProgressCards) {
                    return new Promise((resolve) => {
                        const interval = setInterval(() => {
                            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                                PENDING_REQUESTS++;
                                clearInterval(interval);
                                resolve(requestConfig);
                            }
                        }, INTERVAL_MS);
                    });
                }

                return Promise.resolve(requestConfig);
            },
            (error) => {
                // console.log(error);
                return Promise.reject(error);
            }
        );

        axiosInstance.interceptors.response.use(
            (response) => {
                // console.log(response);
                const responseProgressCards = response.config.method === 'post' && response.config.url?.includes('/progress/cards/');
                if (responseProgressCards) {
                    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                }

                return Promise.resolve(response);
            },
            (error) => {
                // console.log(error);
                const responseProgressCards = error.config?.method === 'post' && error.config?.url?.includes('/progress/cards/');
                if (responseProgressCards) {
                    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                }

                // 500
                if (error.message === 'Network Error' && !error.response) {
                    // history.push(history.location.pathname, {
                    //     errorStatusCode: 500,
                    // });
                    navigate(location.pathname, {
                        state: {
                            errorStatusCode: 500,
                        },
                    });
                    // console.log('Network Error - make sure API is running');
                    return Promise.reject(error);
                }

                if (error.message === undefined && !error.response) {
                    // console.log('request cancelled?');
                    return Promise.reject(error);
                }

                // console.log(error);

                // if (error.code || error.message) {
                //     return Promise.reject(error);
                // }

                const { status, statusText, data } = error?.response as { status: number; statusText: string; data: any };

                // console.log(status, statusText, data);
                if (data) {
                    const { error } = data as {
                        error: string;
                        error_description: string;
                        hint: string;
                        message: string;
                    };
                    // console.log(error, error_description, hint, message)
                    if (error === 'invalid_request') {
                        gotoLoginAndForceLogout();
                        return Promise.reject(error);
                    }
                }

                // 401
                if (status === 401) {
                    // console.log(statusText);

                    if (statusText !== 'Unauthorized') {
                        // console.log('401 and no Unauthorized, go back to login');
                        gotoLoginAndForceLogout();
                        return Promise.reject(error);
                    } else {
                        // Check localstored user
                        const loggedInUser = getLocalStorageUser();
                        if (!loggedInUser) {
                            gotoLoginAndForceLogout();
                            return Promise.reject(error);
                        } else {
                            // Check Refreshtoken
                            const user: User = JSON.parse(loggedInUser);

                            // console.log('Original user token:', user.token);

                            // Refresh token
                            delete axiosInstance.defaults.headers.common['Authorization'];

                            const baseUrl = getBaseUrl();
                            console.log('baseUrl:', baseUrl);

                            const getNewRefresh = async () => {
                                try {
                                    const response = await axiosInstance.post(`${baseUrl}/token/refresh`, {
                                        refresh_token: user.token.refresh_token,
                                    });

                                    // console.log('/token/refresh response', response);
                                    const token: Token = response.data;
                                    // console.log('refreshToken created:', token);
                                    user.token = token;

                                    setLocalStorageUser(user);

                                    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token.access_token}` };

                                    window.location.reload();

                                    return Promise.resolve(response);
                                } catch (e) {
                                    // console.log('token/refresh error:', e);
                                    gotoLoginAndForceLogout();
                                    return Promise.resolve();
                                }
                            };

                            getNewRefresh();
                        }
                    }
                }

                // 403
                if (status === 403) {
                    // gotoLoginAndForceLogout();
                    return Promise.reject(error);
                }

                // console.log(status);
                // Redirects on statusCode. Gets handled by ErrorHandler
                // 404, 500, 503

                if (status === 404) {
                    // history.replace('/home');
                    navigate('/', { replace: true });
                    return Promise.reject(error);
                }

                // history.push(history.location.pathname, {
                //     errorStatusCode: status,
                // });

                // console.log(status);

                navigate(location.pathname, {
                    state: {
                        errorStatusCode: status,
                    },
                });
                return Promise.reject(error);
            }
        );
    };

    // useEffect(() => {
    //     initInterceptors();
    // }, []);

    return { initInterceptors, axiosInstance };
};

export default axiosInstance;
