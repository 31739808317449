import { faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import ExtendedMuiList from '../../../../../components/ExtendedMuiList/ExtendedMuiList';
import { useConfig } from '../../../../../context/ConfigContext';
import useAxiosFetch from '../../../../../hooks/useAxiosFetch';
import { Tenant } from '../../../../../models/Tenant';
import classes from './TenantsList.module.scss';

interface TenantsListProps {
    excludeId: string;
    onMoveQuestHandler: (tenant: Tenant) => void;
}

const TenantsList: FunctionComponent<TenantsListProps> = ({ excludeId, onMoveQuestHandler }) => {
    const { config } = useConfig();
    const { t } = useTranslation();

    const { data: dataTenants, loading: dataTenantsLoading } = useAxiosFetch<Tenant[]>({
        url: `${config.baseUrl}/tenants/exclude/${excludeId}`,
        method: 'get',
    });

    const [tenants, setTenants] = useState<Tenant[]>([]);

    useEffect(() => {
        if (dataTenants) {
            // console.log(dataTenants);
            setTenants(dataTenants);
        }
    }, [dataTenants]);

    const renderTenantItem = (tenant: Tenant) => {
        return (
            <div
                key={`tenant_${tenant.id}`}
                className={classes.TenantListItem}
            >
                <div className={`${classes.TenantName}`}>
                    <FontAwesomeIcon icon={faUser} />
                    {tenant.name}
                </div>
                <Button
                    text=""
                    icon={<FontAwesomeIcon icon={faUpload} />}
                    onClick={() => onMoveQuestHandler(tenant)}
                />
            </div>
        );
    };

    const filterDataBySearch = (items: Tenant[], searchValues: string[]) => {
        // console.log(items);
        return items.filter((item) => {
            // Specific data / array to searchable string
            const name = item.name;
            //
            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <div className={`Box ${classes.TenantsListContainer}`}>
            <div className={classes.TenantsListHeader}>
                <h3 className={classes.TenantsListTitle}>{t('Common:TENANTS_HEADER')}</h3>
                <p>{t('Common:TENANTS_QUEST_MOVE_INSTRUCTION')}</p>
            </div>

            <ExtendedMuiList
                loading={dataTenantsLoading}
                filterDataBySearch={filterDataBySearch}
                searchInputLabel={t('Common:TENANTS_SEARCH_INPUT_LABEL')}
                noItemsMessage={t('Common:LIST_MESSAGE_NO_TENANTS')}
                noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_TENANTS')}
                items={tenants}
                renderItem={renderTenantItem}
                itemsListClasses={classes.TenantsList}
            />
        </div>
    );
};

export default TenantsList;
