import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Permission } from '../../models/User';
import classes from './DropDown.module.scss';

export enum ItemType {
    Link = 'link',
    Button = 'button',
    Section = 'section',
}

export enum ItemLocation {
    Start = 'start',
    Mid = 'mid',
    End = 'end',
}

export type DropDownItem = {
    id: number;
    value: string;
    type: ItemType;
    location?: ItemLocation;
    icon?: IconDefinition;
    link?: string;
    permission?: Permission;
    button?: JSX.Element;
    onClick?: () => void;
};

export type DropDownSection = {
    id: number;
    value: string;
    type: ItemType;
    items: DropDownItem[];
    permission?: Permission;
};

export interface IDropDownProps {
    items: DropDownSection[];
    isDesktop?: boolean;
    onClose?: () => void;
    isOpen: boolean;
    style?: CSSProperties;
}

const DropDown: FunctionComponent<IDropDownProps> = (props) => {
    const { items, isOpen, onClose, isDesktop } = props;
    const handleOnClick = (onClick?: () => void) => {
        onClick?.();
        onClose?.();
    };
    const openedClass = isOpen ? classes.isOpen : classes.isClosed;

    return (
        <ul
            className={`${classes.List} ${classes[isDesktop ? 'isDesktop' : 'isMobile']} ${openedClass} Box ${classes.alt}`}
            style={props?.style}
        >
            {items.map((section, i, arr) => {
                const enabled =
                    (section.permission && section.permission === 'permission.manage' && isDesktop) || section.permission === 'user.view';

                return (
                    enabled && (
                        <div
                            className={classes.ListSection}
                            key={i}
                        >
                            {section.value !== '' && (
                                <p className={classes.ListSectionHeader}>
                                    <strong>{section.value}</strong>
                                </p>
                            )}

                            {section.items.map((item) => (
                                <li
                                    className={`ListItem ${item.type}`}
                                    key={section.id + '_' + item.id}
                                >
                                    {item.type === ItemType.Link && (
                                        <NavLink
                                            className={classes.Navlink}
                                            to={item.link || ''}
                                            onClick={() => handleOnClick(item.onClick)}
                                        >
                                            {item.icon && (
                                                <FontAwesomeIcon
                                                    className={classes.NavIcon}
                                                    icon={item.icon}
                                                />
                                            )}
                                            {item.value}
                                        </NavLink>
                                    )}
                                    {item.type === ItemType.Button &&
                                        (!item.location || item.location === ItemLocation.Mid) &&
                                        (item.button ? (
                                            item.button
                                        ) : (
                                            <button
                                                className={`${classes.ListItemButton} ${
                                                    classes[isDesktop ? 'isDesktop' : 'isMobile']
                                                } btn btn-flavour btn-flavour--alt btn-flavour--dropdown`}
                                                type="button"
                                                onClick={() => handleOnClick(item.onClick)}
                                            >
                                                <span>{item.value}</span>
                                            </button>
                                        ))}
                                    {item.type === ItemType.Button && item.location && item.location === ItemLocation.End && (
                                        <button
                                            className={`${classes.ListItemButton} ${
                                                classes[isDesktop ? 'isDesktop' : 'isMobile']
                                            } btn btn-flavour btn-flavour--danger btn-flavour--dropdown`}
                                            type="button"
                                            onClick={() => handleOnClick(item.onClick)}
                                        >
                                            <span>{item.value}</span>
                                        </button>
                                    )}
                                </li>
                            ))}

                            {arr.length - 1 !== i && <div className={classes.ListSectionDivider}></div>}
                        </div>
                    )
                );
            })}
        </ul>
    );
};

export default DropDown;
