import React, { FunctionComponent, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Fragment } from 'react';

type Props = {
    children?: ReactNode;
};

const ErrorHandler: FunctionComponent<Props> = ({ children }) => {
    const location = useLocation();
    const statusCode = get(location.state, 'errorStatusCode');

    // 401 already redirected to login
    // 403
    // 404
    // 500
    // 503
    switch (statusCode) {
        case 403:
            return <ErrorPage statusCode={statusCode} />;
        case 404:
            return <ErrorPage statusCode={statusCode} />;
        case 500:
            return <ErrorPage statusCode={statusCode} />;
        case 503:
            return <ErrorPage statusCode={statusCode} />;

        default:
            return <Fragment>{children}</Fragment>;
    }
};

export default ErrorHandler;
