import React from 'react';
import { FunctionComponent } from 'react';
import classes from './Welcome.module.scss';
import { useConfig } from '../../../context/ConfigContext';

const Welcome: FunctionComponent = () => {
    const { loadingConfig } = useConfig();

    // const defaultWelcomeCopy = config.tenant?.texts['login']?.map((copy) => {
    //     return (
    //         <div
    //             key={copy.text_key}
    //             dangerouslySetInnerHTML={{
    //                 __html: DOMPurify.sanitize(
    //                     copy.elements.reverse().find((element) => element.elementable_type === 'text_element')
    //                         ?.elementable.body || '',
    //                 ),
    //             }}
    //         ></div>
    //     );
    // });

    const defaultWelcomeCopy = <div>Hallo admin</div>;

    return <div className={`${classes.Welcome}`}>{!loadingConfig && defaultWelcomeCopy}</div>;
};

export default Welcome;
