import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment, TablePagination, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TablePaginationActions from '../Pagination/TablePaginationActions/TablePaginationActions';
import usePagination from '../Pagination/usePagination';
import classes from './ExtendedMuiList.module.scss';

export interface ExtendedMuiListProps<T> {
    items: T[];
    noItemsMessage: string;
    noItemsFilteredMessage: string;
    renderItem: (item: T) => JSX.Element;
    renderListHeaders?: JSX.Element;
    loading: boolean;
    itemsListContainerClasses?: string;
    itemsListClasses?: string;
    searchInputLabel?: string;
    searchAutoFocus?: boolean;
    filterDataBySearch?: (items: T[], searchValues: string[]) => T[];
}

const ExtendedMuiList = <T,>(props: ExtendedMuiListProps<T>) => {
    // SEARCH
    const [filteredResults, setFilteredResults] = useState<T[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const searchInputDelay = 300;
    const searchInputRef = useRef<HTMLInputElement>(null);

    // PAGINATION
    const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } = usePagination(10);

    const searchedItems = searchInput.length >= 1 && props.filterDataBySearch ? filteredResults : props.items;
    const itemsToMap = rowsPerPage > 0 ? searchedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];
    const itemsList = itemsToMap.map((item, index) => {
        const muiListItem = (
            <div
                key={`item_${index}`}
                className={classes.MuiListItem}
            >
                {props.renderItem(item)}
            </div>
        );
        return muiListItem;
    });

    useEffect(() => {
        searchItems(searchInput);
    }, [props.items]);

    const debouncedSearchHandler = useMemo(
        () =>
            debounce((value) => {
                // console.log(value);
                searchItems(value);
            }, searchInputDelay),

        [props]
    );

    // const debouncedSearchHandler = (value) => searchItems(value);

    const searchItems = (searchValue: string) => {
        if (!props.filterDataBySearch) return;

        // console.log('searchItems', searchValue);
        setSearchInput(searchValue);

        const searchValues = searchValue.split(' ').filter((item) => item !== '');
        // console.log(searchValue, searchValues);

        if (searchValue !== '') {
            // console.log('should use props.filterDataBySearch:', props.items);
            const filteredData = props.filterDataBySearch(props.items, searchValues);
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(props.items);
        }

        handleChangePage(null, 0);
    };

    const clearSearch = () => {
        setSearchInput('');
        setFilteredResults(props.items);
        searchInputRef.current?.focus();
    };

    return (
        <div className={`${classes.ExtendedMuiListContainer} ${props.itemsListContainerClasses}`}>
            {props.filterDataBySearch && props.items.length > rowsPerPage && (
                <TextField
                    inputRef={searchInputRef}
                    id="standard-search"
                    label={props.searchInputLabel}
                    type="search"
                    value={searchInput}
                    autoFocus={props.searchAutoFocus}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </InputAdornment>
                        ),
                        endAdornment: searchInput.length > 0 && (
                            <IconButton onClick={clearSearch}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </IconButton>
                        ),
                    }}
                    onChange={(e) => {
                        // console.log(e.target.value);
                        setSearchInput(e.target.value);
                        debouncedSearchHandler(e.target.value);
                    }}
                />
            )}

            <div className={`${classes.ExtendedMuiItemListContainer}`}>
                {props.renderListHeaders && props.renderListHeaders}

                {props.loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!props.loading && (
                    <div className={`${classes.ExtendedMuiItemList} ${props.itemsListClasses}`}>
                        {props.items.length > 0 ? (itemsToMap.length > 0 ? itemsList : props.noItemsFilteredMessage) : props.noItemsMessage}
                    </div>
                )}
            </div>

            {!props.loading && props.items.length > rowsPerPage && (
                <table>
                    <tbody>
                        <tr>
                            <TablePagination
                                className={classes.TablePagination}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={searchedItems.length}
                                page={page}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                                ActionsComponent={TablePaginationActions}
                            />
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ExtendedMuiList;
