import React, { FunctionComponent } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import classes from './App.module.scss';
import CustomToast from './components/CustomToast/CustomToast';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import Header from './components/Header/Header';
import PageDecorator from './components/PageDecorator/PageDecorator';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { useConfig } from './context/ConfigContext';
import { ThemeProvider } from './context/ThemeProvider';
import ScrollToTop from './hooks/scrollToTop';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import PasswordRequest from './pages/Password/PasswordRequest/PasswordRequest';
import PasswordReset from './pages/Password/PasswordReset/PasswordReset';
import Tenants from './pages/Tenants/Tenants';

const App: FunctionComponent = () => {
    // const { auth } = useAuth();
    const { loadingConfig, loading } = useConfig();
    const location = useLocation();

    return (
        <>
            {/* <CssBaseline /> */}
            {!loadingConfig && !loading && (
                <ThemeProvider>
                    {/* {loading && (
                        <div className="app-loading">
                            <div className="general-spinner">
                                <div className="spinner card Box"></div>
                                <h2 className="loading-text h6">Loading hero HQ</h2>
                            </div>
                        </div>
                    )} */}

                    <div id="app">
                        <div className={`${classes.App} ${loading ? classes.Loading : classes.Loaded}`}>
                            <ErrorHandler>
                                <ScrollToTop />

                                <Routes>
                                    <Route
                                        element={
                                            <PageDecorator>
                                                <Header></Header>
                                                <Outlet />
                                            </PageDecorator>
                                        }
                                    >
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate
                                                    to="/"
                                                    state={{ from: location }}
                                                />
                                            }
                                        />
                                        {/* <Route
                                                path="invites"
                                                element={
                                                    <PrivateRoute>
                                                        <Invite />
                                                    </PrivateRoute>
                                                }
                                            /> */}
                                        <Route
                                            path="password/request"
                                            element={<PasswordRequest />}
                                        />
                                        <Route
                                            path="password/reset/:token"
                                            element={<PasswordReset />}
                                        />

                                        <Route
                                            index
                                            element={
                                                <PrivateRoute>
                                                    <Home />
                                                </PrivateRoute>
                                            }
                                        />

                                        <Route
                                            path="tenants/*"
                                            element={<Tenants />}
                                        />

                                        <Route
                                            path="login"
                                            element={<Login />}
                                        />
                                    </Route>
                                </Routes>
                                <CustomToast />
                            </ErrorHandler>
                        </div>
                    </div>
                </ThemeProvider>
            )}
        </>
    );
};

export default App;
