import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import Message from '../../../components/Message/Message';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { Token, User } from '../../../models/User';
import Axios from '../../../utils/axios-instance';
import { setLocalStorageUser } from '../../../utils/flavourstorage';
import { VerificationData } from '../../EmailValidation/EmailValidation';
import Welcome from '../Welcome/Welcome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classes from './LoginForm.module.scss';
import { TextField } from '@mui/material';

export interface LoginResponse extends Token {
    user: User;
}

const LoginForm: FunctionComponent = () => {
    const { config } = useConfig();
    // const history = useHistory();
    const navigate = useNavigate();
    const location = useLocation();
    const { autoLogin } = useAuth();
    const [isError, setIsError] = useState(false);
    const [errorStatus, setErrorStatus] = useState<number | undefined>();
    const { t } = useTranslation();
    // const buttonLoadingIconColor = getColor('--brand-color-12');
    // const [defaultWelcome, setDefaultWelcome] = useState('');
    const [verificationData, setVerificationData] = useState<VerificationData | null>();

    // useEffect(() => {
    //     if (!loadingConfig) {
    //         const customFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == false);
    //         const defaultFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == true);
    //         setDefaultWelcome(
    //             getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
    //         );
    //     }
    // }, [loadingConfig]);

    useEffect(() => {
        // console.log('location.state:', location.state);
        const locationState = location.state as VerificationData;
        if (locationState?.verificationData) {
            setVerificationData(locationState);
        }
    }, [location]);

    const schema = Yup.object({
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
        password: Yup.string().required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED')),
    });

    const error =
        errorStatus === 403 ? (
            <div className={classes.formFeedback}>
                <Message text={t('Common:ACCOUNT_INACTIVE_MESSAGE')}></Message>
            </div>
        ) : (
            <div className={classes.formFeedback}>
                <Message text={t('Auth:INPUT_ERROR_CREDENTIAL_MISMATCH')}></Message>
            </div>
        );

    const notify = () => toast.success(t('Common:TOAST_LOGIN_SUCCESS'));

    // const registerButtonHandler = () => {
    //     // history.push('/register');
    //     navigate('/register');
    // };

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            validateOnBlur
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // console.log(values);

                Axios.post(`${config.baseUrl}/login`, values)
                    .then((response) => {
                        setSubmitting(false);
                        setIsError(false);
                        setErrorStatus(undefined);

                        // console.log(response.data);

                        const loginResponse: LoginResponse = response.data;

                        if (loginResponse) {
                            // console.log(loginResponse);

                            const user: User = {
                                ...loginResponse.user,
                                token: {
                                    access_token: loginResponse.access_token,
                                    expires_in: loginResponse.expires_in,
                                    refresh_token: loginResponse.refresh_token,
                                    token_type: loginResponse.token_type,
                                },
                            };

                            setLocalStorageUser(user);
                            autoLogin(user);

                            Axios.defaults.headers.common = { Authorization: `Bearer ${loginResponse.access_token}` };

                            if (user.email_verified) {
                                // console.log('Already verified! Go to home!');
                                notify();
                                // history.replace('/home');
                                navigate('/', { replace: true });
                            } else {
                                // console.log('Not YET verified!');
                                if (verificationData?.verificationData) {
                                    // /email/validation/:signature/:userId/:token/:expires
                                    const { userId, token, expires, signature } = verificationData.verificationData;
                                    // console.log('with verificationData', verificationData.verificationData);
                                    // history.replace(`/email/validation/${signature}/${userId}/${token}/${expires}`);
                                    navigate(`/email/validation/${signature}/${userId}/${token}/${expires}`, { replace: true });
                                } else {
                                    // console.log('withOut verificationData');
                                    // history.replace(`/email/validation/000/000/000/000`);
                                    navigate(`/email/validation/000/000/000/000`, { replace: true });
                                }
                            }
                        } else {
                            // console.log('No data in response!');
                        }
                    })
                    .catch((error) => {
                        const { status } = error?.response as {
                            status: number;
                            statusText: string;
                            data: any;
                        };

                        setErrorStatus(status);

                        setSubmitting(false);
                        setIsError(true);
                    });
            }}
        >
            {(props) => {
                const { values, touched, errors, isSubmitting, isValid, dirty, handleChange, handleBlur, handleSubmit } = props;

                // const emailErrorClassName = !!errors.email && !!touched.email ? classes.error : '';
                // const passwordErrorClassName = !!errors.password && !!touched.password ? classes.error : '';
                const disabledSubmit = isSubmitting || !(isValid && dirty);

                return (
                    <form
                        className={classes.Form}
                        noValidate={true}
                        onSubmit={handleSubmit}
                    >
                        <div className={`${classes.Login} Box`}>
                            <div className={classes.LoginContent}>
                                <Welcome></Welcome>

                                <div className={`${classes.LoginForm}`}>
                                    <div className={classes.formGroup}>
                                        {/* <label htmlFor="email">{t('Common:EMAIL_LABEL')}</label> */}
                                        {/* <input
                                            className={`form-control ${emailErrorClassName}`}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="username"
                                            required
                                            autoFocus
                                            autoCorrect="off"
                                            autoCapitalize="none"
                                            placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        /> */}
                                        <TextField
                                            margin="dense"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            variant="standard"
                                            autoComplete="username"
                                            // focused
                                            required
                                            autoFocus
                                            autoCorrect="off"
                                            autoCapitalize="none"
                                            placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                        {/* {errors.email && touched.email && <div className={classes.inputFeedback}>{errors.email}</div>} */}
                                    </div>

                                    <div className={classes.formGroup}>
                                        {/* <label htmlFor="password">{t('Common:PASSWORD')}</label> */}
                                        {/* <input
                                            className={`form-control ${passwordErrorClassName}`}
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            placeholder={t('Common:PASSWORD_PLACEHOLDER')}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        /> */}
                                        <TextField
                                            margin="dense"
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            variant="standard"
                                            autoComplete="current-password"
                                            required
                                            placeholder={t('Common:PASSWORD_PLACEHOLDER')}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                        {/* {errors.password && touched.password && (
                                            <div className={classes.inputFeedback}>{errors.password}</div>
                                        )} */}
                                    </div>

                                    {isError && error}

                                    <button
                                        className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                                        type="submit"
                                        disabled={disabledSubmit}
                                    >
                                        {isSubmitting && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                spin={true}
                                            />
                                        )}
                                        {t('Login:BUTTON_LOGIN')}
                                    </button>

                                    <NavLink
                                        className={`${classes.NavLink}`}
                                        to="/password/request"
                                    >
                                        {t('Login:FORGOT_PASSWORD')}
                                    </NavLink>
                                </div>

                                {/* {!loading && config.tenant?.is_public && (
                                    <div className={classes.PublicAndRegister}>
                                        <p>{t('Login:PUBLIC_DOMAIN')}</p>
                                        <button
                                            className={`${classes.registerButton} btn btn-flavour btn-flavour--register`}
                                            type="button"
                                            onClick={registerButtonHandler}
                                        >
                                            {t('Login:BUTTON_GO_TO_REGISTER')}
                                        </button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <div className={`${classes.formActions} ${classes.flexGap}`}></div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default LoginForm;
