import { useCallback, useEffect, useRef } from 'react';

export const useClickOutsideListenerRef = (onClose: () => void): React.RefObject<HTMLDivElement> => {
    const ref = useRef<HTMLDivElement>(null);

    const escapeListener = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }, []);
    const clickListener = useCallback(
        (e: MouseEvent) => {
            const node = ref.current;
            // console.log('node:', node);

            if (node) {
                const el = e.target;
                const contains = node.contains(el as Node);
                // console.log('contains:', contains);
                if (!contains) {
                    // console.log('useClickOutsideListenerRef - closing');
                    // e.preventDefault();
                    onClose?.();
                }
            }
        },

        [ref.current]
    );
    useEffect(() => {
        document.addEventListener('click', clickListener);
        document.addEventListener('keyup', escapeListener);
        return () => {
            document.removeEventListener('click', clickListener);
            document.removeEventListener('keyup', escapeListener);
        };
    }, []);

    return ref;
};
