import { faPencil, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../components/Button/Button';
import { Tenant } from '../../../../../models/Tenant';
import classes from './TenantItem.module.scss';

interface TenantItemProps {
    tenant: Tenant;
}

const TenantItem: FunctionComponent<TenantItemProps> = ({ tenant }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onEditHandler = () => {
        navigate(`/tenants/${tenant.id}`);
    };

    const onVisitHandler = () => {
        window.open(
            `${process.env.REACT_APP_URL_SCHEME}${tenant.id}${process.env.REACT_APP_URL_SLD_SUFFIX || ''}.${
                process.env.REACT_APP_URL_TLD
            }/app`
        );
    };

    return (
        <div
            key={`tenant_${tenant.id}`}
            className={classes.TenantItem}
        >
            <div className={`${classes.TenantsListItem} ${classes.TenantListId} wrap-text`}>{tenant.id}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListName} wrap-text`}>{tenant.name}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListUserCount} wrap-text`}>{tenant.user_count}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListAvailability} wrap-text`}>
                {tenant.is_public ? t('Common:PUBLIC') : t('Common:PRIVATE')}
            </div>
            <Button
                text=""
                icon={<FontAwesomeIcon icon={faPencil} />}
                onClick={onEditHandler}
            />
            <Button
                onClick={onVisitHandler}
                icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
            />
        </div>
    );
};

export default TenantItem;
