import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useInterceptors } from '../utils/axios-instance';
import { getSubdomain } from '../utils/Info';
import { Config, CtxProvider } from './ConfigContext';

export const getConfig = (): { baseUrl: string; subdomain: string } => {
    const subdomain = getSubdomain();
    // const tenant = subdomain;
    // Subdomain added to baseUrl within Axios instance.
    const baseUrl = '/admin'; // addTenantToBaseUrl(tenant);
    return { baseUrl, subdomain };
};

type Props = {
    children?: ReactNode;
};

export const ConfigProvider: FunctionComponent<Props> = ({ children }) => {
    const { initInterceptors } = useInterceptors();
    const [loading, setLoading] = useState(true);

    const defaultConfig: Config = {
        baseUrl: '/admin',
    };

    const [config, setConfig] = useState<Config>(defaultConfig);

    useEffect(() => {
        initInterceptors();
        setLoading(false);
    }, []);

    return <CtxProvider value={{ config, setConfig, loading }}>{children}</CtxProvider>;
};
