import React, { FunctionComponent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavButton.module.scss';
interface IProps {
    to: string;
    label?: string;
    onClick?: () => void;
    children?: ReactNode;
}

const NavButton: FunctionComponent<IProps> = (props) => {
    return (
        <NavLink
            className={({ isActive }) =>
                `${classes.NavButton} nav-link btn btn-flavour btn-flavour--round ${isActive ? classes.Selected : ''}`
            }
            to={props.to}
            onClick={() => props.onClick?.()}
        >
            {props.children}
            <label className={classes.Label}>{props.label}</label>
        </NavLink>
    );
};

export default NavButton;
