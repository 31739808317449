import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useState } from 'react';
import Axios from '../../../utils/axios-instance';
import * as Yup from 'yup';
import classes from './PasswordReset.module.scss';
import { useNavigate, useParams } from 'react-router';
import Message from '../../../components/Message/Message';
import { useConfig } from '../../../context/ConfigContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import BoxHeader from '../../../components/BoxHeader/BoxHeader';
import CountDown from '../../../components/CountDown/CountDown';

type ParamTypes = {
    token: string;
};

const PasswordReset: FunctionComponent = () => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const { token } = useParams<ParamTypes>();
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    const schema = Yup.object({
        password: Yup.string()
            .required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED'))
            .min(8, t('Common:INPUT_ERROR_PASSWORD_MIN', { min: 8 }))
            .max(255, t('Common:INPUT_ERROR_PASSWORD_MAX', { max: 255 })),
        password_confirmation: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password')], t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION'))
                .required(t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION')),
        }),
        email: Yup.string().required(t('Common:INPUT_ERROR_EMAIL_REQUIRED')),
    });

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword ? false : true);
    };

    const toLogin = () => {
        // history.replace('/login');
        navigate('login', { replace: true });
    };

    const form = (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
                token: token,
                email: '',
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                    await Axios.post(`${config.baseUrl}/password/reset`, values);

                    setSubmitting(false);
                    setIsSubmitted(true);
                    setIsError(false);

                    // console.log(response.data);
                } catch (error) {
                    // No error should happen here.
                    // console.log(error);

                    setSubmitting(false);
                    setIsSubmitted(false);
                    setIsError(true);
                }
            }}
        >
            {(formikProps) => {
                const { values, touched, errors, isSubmitting, isValid, dirty, handleChange, handleBlur, handleSubmit } = formikProps;

                const passwordErrorClassName = !!errors.password && !!touched.password ? classes.error : '';
                const passwordConfirmationErrorClassName =
                    !!errors.password_confirmation && !!touched.password_confirmation ? classes.error : '';

                const disabledSubmit = isSubmitting || !(isValid && dirty);
                // console.log(isSubmitting, isValid, dirty, disabledSubmit);

                return (
                    <form
                        noValidate={true}
                        onSubmit={handleSubmit}
                        className={classes.form}
                    >
                        <div className={`${classes.PasswordResetForm} Box`}>
                            <BoxHeader
                                title={t('Auth:PASSWORD_RESET_TITLE')}
                                subtitle={t('Auth:PASSWORD_RESET_SUBTITLE')}
                            ></BoxHeader>
                            <div className={classes.formGroup}>
                                <label htmlFor="email">{t('Common:EMAIL_LABEL')}</label>
                                <input
                                    className={`form-control`}
                                    id="email"
                                    name="email"
                                    required
                                    aria-describedby="email"
                                    type="email"
                                    placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label htmlFor="password">{t('Auth:PASSWORD_RESET_NEW_PASSWORD')}</label>
                                <div className={classes.passwordWrapper}>
                                    <input
                                        className={`form-control ${passwordErrorClassName}`}
                                        id="new-password"
                                        name="password"
                                        autoComplete="new-password"
                                        required
                                        aria-describedby="password-constraints"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <button
                                        className={`btn ${classes.PasswordVisibilityButton}`}
                                        type="button"
                                        onClick={togglePasswordVisiblity}
                                        tabIndex={-1}
                                        aria-label="Show password as plain text. Warning: this will display your password on the screen."
                                    >
                                        {showPassword ? (
                                            <FontAwesomeIcon
                                                className={classes.PasswordVisibilityIcon}
                                                icon={faEye}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className={classes.PasswordVisibilityIcon}
                                                icon={faEyeSlash}
                                            />
                                        )}
                                    </button>
                                </div>
                                <div className={classes.passwordHint}>{t('Auth:PASSWORD_RESET_PASSWORD_HINT')}</div>
                                {errors.password && touched.password && <div className={classes.inputFeedback}>{errors.password}</div>}
                            </div>
                            <div className={classes.formGroup}>
                                <label htmlFor="password_confirmation">{t('Auth:PASSWORD_CONFIRM_LABEL')}</label>
                                <div className={classes.passwordWrapper}>
                                    <input
                                        className={`form-control ${passwordConfirmationErrorClassName}`}
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        autoComplete="new-password"
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                        value={values.password_confirmation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                {/* <div className={styles.passwordHint}>
                                    Using a phrase of four random words (like: pillow jar symbol lift) is secure and
                                    easy to remember.
                                </div> */}
                                {errors.password_confirmation && touched.password_confirmation && (
                                    <div className={classes.inputFeedback}>{errors.password_confirmation}</div>
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formActions} ${classes.flexGap}`}>
                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {t('Auth:PASSWORD_RESET_BUTTON_CONFIRM')}
                            </button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );

    const newPasswordIsSet = (
        <Fragment>
            <div className={`Box ${classes.PasswordIsSet}`}>
                <BoxHeader
                    title={t('Auth:PASSWORD_RESET_TITLE')}
                    subtitle={t('Auth:PASSWORD_RESET_SUCCESS_SUBTITLE')}
                ></BoxHeader>

                <p>
                    {t('Auth:RETURN_TO_LOGIN')}{' '}
                    <CountDown
                        seconds={5}
                        callbackHandler={toLogin}
                    ></CountDown>
                </p>
            </div>
        </Fragment>
    );

    const error = <Message text={t('Auth:PASSWORD_RESET_ERROR')}></Message>;

    return <div className={classes.PasswordReset}>{isSubmitted ? newPasswordIsSet : isError ? error : form}</div>;
};

export default PasswordReset;
